<template>
  <div v-if="isShowAppeal" class="modal">
    <div class="modal__container">
      <div class="club-registration-modal appeal-modal">
        <button class="club-registration-modal__close" @click="close"></button>
        <div class="club-registration-modal__title">
          {{ $t("appealForm.title") }}
        </div>
        <validation-observer ref="formAppeal">
          <form
            class="club-registration-modal__wrapper is-desktop"
            @submit.prevent="sendForm"
          >
            <div class="club-registration-modal__name input">
              <div class="club-registration-modal__theme">
                <span class="input-label">{{ $t("appealForm.theme") }}</span>
                <p>
                  {{ user.topicTitle }}
                </p>
              </div>
            </div>
            <div class="club-registration-modal__name input">
              <validation-provider v-slot="{ errors }" rules="required">
                <input
                  ref="first_name"
                  v-model="user.name"
                  type="text"
                  class="input-field"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required" ? $t("appealForm.fio") : ""
                }}</label>
                <label v-else class="input-label">{{
                  $t("appealForm.fio")
                }}</label>
              </validation-provider>
            </div>

            <div class="club-registration-modal__number input">
              <validation-provider v-slot="{ errors }" rules="required">
                <input
                  ref="userPhone"
                  v-model="user.phone"
                  type="text"
                  v-mask="'(###) ### ## ##'"
                  class="input-field input-phone"
                  title="Введите номер"
                  @focus="focusPhone"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterPhone")
                    : $t("phoneIncorrectly")
                }}</label>
                <label v-else class="input-label">{{
                  $t("appealForm.phone")
                }}</label>
                <span v-if="isShowPhoneSpan" class="input-phone-span">
                  +7
                </span>
              </validation-provider>
            </div>

            <div class="club-registration-modal__email input">
              <validation-provider v-slot="{ errors }" rules="required|email">
                <input v-model="user.email" type="email" class="input-field" />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterEmail")
                    : $t("emailIncorrectly")
                }}</label>
                <label v-else for="" class="input-label">
                  {{ $t("appealForm.email") }}</label
                >
              </validation-provider>
            </div>

            <div
              class="club-registration-modal__goal input"
              :class="{ active: isTextareaFocus }"
            >
              <validation-provider v-slot="{ errors }" rules="required">
                <textarea
                  v-model="user.goal"
                  class="input-field input-field__goal"
                  @click.stop="isTextareaFocus = true"
                ></textarea>
                <label v-if="errors.length" class="input-label error">{{
                  $t("appealForm.goal")
                }}</label>
                <label v-else for="" class="input-label">
                  {{ $t("appealForm.goal") }}</label
                >
              </validation-provider>
            </div>
            <button class="club-registration-modal__button" type="submit">
              {{ $t("appealForm.submit") }}
            </button>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
  <app-modal-received
    v-else
    @close="closeModalAppeal"
    title="receivedAppeal"
    text="contactAppeal"
    image="donute.svg"
    btn="goodAppeal"
  />
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { sendContactReply } from "@/api/contact-reply";
export default {
  name: "AppModalAppeal",
  components: {
    ValidationProvider,
    ValidationObserver,
    AppModalReceived: () => import("@/components/modal/AppModalReceived.vue"),
  },
  props: {
    topicTitle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: {
        phone: "",
        name: "",
        email: "",
        topicTitle: "",
        topicId: null,
        goal: "",
      },
      isShowAppeal: true,
      isShowPhoneSpan: false,
      isTextareaFocus: false,
    };
  },
  watch: {
    topicTitle: {
      handler(val) {
        this.user.topicTitle =
          this.$i18n.locale === "ru"
            ? val.attributes.label_ru
            : val.attributes.label_kz;
        this.user.topicId = val.id;
      },
    },
  },
  mounted() {
    if (this.topicTitle) {
      this.user.topicTitle =
        this.$i18n.locale === "ru"
          ? this.topicTitle.attributes.label_ru
          : this.topicTitle.attributes.label_kz;
      this.user.topicId = this.topicTitle.id;
    }
    document?.addEventListener("click", () => {
      this.isTextareaFocus = false;
    });
  },
  methods: {
    close() {
      this.$emit("close");
      document.querySelector("body").classList.remove("no-scroll");
    },
    closeModalAppeal() {
      this.$emit("close");
      this.isShowAppeal = true;
    },
    sendForm() {
      this.$refs.formAppeal.validate().then((success) => {
        if (success) {
          let user = {
            notification_email_groups: this.user.topicId,
            name: this.user.name,
            phone: `+7 ${this.user.phone}`,
            email: this.user.email,
            contact_target: this.user.goal,
          };
          sendContactReply({ data: { ...user } }).then(() => {
            this.isShowAppeal = false;
          });
        }
      });
    },
    focusPhone() {
      this.isShowPhoneSpan = true;
    },
  },
};
</script>
